<template>
  <div class="snt-admin">
    <navbar />
    <router-view />
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'

export default {
  components: {
    Navbar
  },
  created () {
    // this.$vuetify.theme.themes.light = {
    //   primary: '#1BA7E1',
    //   error: '#F04D4D',
    //   success: '#2AB59C',
    //   warning: '#EDA437',
    //   secondary: '#6C6C6C',
    //   background: '#F4F6FA'
    // }
  }
}
</script>

<style  scoped>

</style>
