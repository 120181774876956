<template>
  <div class="snt-navbar d-flex align-center pa-4">
    <v-btn
      :ripple="false"
      to="/"
      class="d-flex align-center mr-10 v-btn--active"
      plain>
      <v-img
        :src="Logo"
        class="mr-2" />
      <div class="font-weight-medium primary--text">
        Shipnity
      </div>
    </v-btn>
    <template v-if="!$shipnity.breakpoint.mobile">
      <v-btn
        v-for="(link, index) in links"
        :key="`menu-${index}`"
        :to="link.to"
        :ripple="false"
        :class="{ 'snt-navbar__menu--active': $route.path === link.to}"
        class="v-btn--active"
        plain>
        {{ $t(link.name) }}
      </v-btn>
    </template>
    <v-spacer />
    <div class="primary--text mx-3">
      {{ shopName }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Logo from '@/assets/img/logo.png'

export default {
  data () {
    return {
      Logo,
      links: [
        {
          to: '/',
          name: 'templateManage'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      shopName: 'auth/shopName'
    })
  }
}
</script>

<style scoped>
  .snt-navbar {
    height: 64px;
    background-color: #ffffff;
    width: 100%;
    border-bottom: 1px solid #E4E4E4;
  }
  .snt-navbar__menu--active {
    color: var(--v-primary-base);
    border-bottom: 4px solid var(--v-primary-base);
    border-radius: 0;
    margin: 0 16px;
    padding-left: 2px !important;
    padding-right: 2px !important;
    height: 64px !important;
  }
</style>
